<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>诊单管理</el-breadcrumb-item>
      <el-breadcrumb-item>诊单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-select v-model="searchstate" placeholder="请选择状态" style="width:100px;margin-right:10px;" @change="getall(1, searchstate)" size="small">
        <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <!-- 用户编号： -->
      <el-input placeholder="请输入用户编号" clearable prefix-icon="el-icon-search" v-model="searchcustid" style="width: 170px;margin-right: 10px;" @clear="getall(1, '1');searchstate=1;showpage=true;" size="small">
      </el-input>
      <span>或</span>
      <el-input placeholder="请输入问诊单编号" clearable @clear="getall(1, '1');searchstate=1;showpage=true;" prefix-icon="el-icon-search" v-model="searchfiel" style="width: 200px;margin-left:10px" size="small">
      </el-input>
      <el-button type="primary" style="margin-left:20px" @click="query" size="small">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%" v-loading="pdfloading" element-loading-text="正在生成PDF，请稍后...">
      <el-table-column prop="KID" label="问诊单编号" width="170"></el-table-column>
      <el-table-column prop="Dis_Name" label="问诊单名" width="200"></el-table-column>
      <el-table-column prop="Cust_ID" label="用户编号" width="130"></el-table-column>
      <el-table-column prop="Cust_Name" label="用户姓名" width="200"></el-table-column>
      <el-table-column prop="Input_ID" label="服务人ID" width="100"></el-table-column>
      <el-table-column prop="doc_name" label="医生姓名" width="100"></el-table-column>
      <el-table-column prop="State" label="状态" width="100">
        <template slot-scope="scope">
          <span>{{ transformstate(scope.row.State) }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="260">
        <template slot-scope="scope">
          <div class="opt">
            <p>
              <el-button size="mini" type="warning" icon="el-icon-view" @click="lookup(scope.row)">查看</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="fpys(scope.row)">分配医生</el-button>
            </p>
            <p>
              <el-button size="mini" type="success" icon="el-icon-edit" @click="
                dialogFormVisible = true;
                row = scope.row;
              ">修改状态</el-button>
              <el-button v-if="scope.row.State == 6" size="mini" type="danger" icon="el-icon-printer" @click="shengchengpdf(scope.row)">生成pdf</el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging" v-if="showpage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
    <el-dialog title="问诊表" :modal="false" :visible.sync="EditZD" width="566px">
      <preview :kid="EditMsg.kid" :custid="EditMsg.custid" v-if="EditZD" :EditZD="EditZD" :noedit="EditMsg.noedit" @subTJshenhe="subTJshenhe" :shenhe.sync="shenhe" ref="uppdf"></preview>
    </el-dialog>

    <el-dialog title="修改状态" :visible.sync="dialogFormVisible">
      <p>患者 {{row.Cust_ID}} 的 {{row.Dis_Name}} 问诊单 {{row.KID}}</p>
      <!-- <p>请选择{{row.KID}}要修改的状态</p> -->
      <el-select v-model="changestate" placeholder="请选择要修改的状态">
        <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>

      <div slot="footer" class="dialog-footer">
        <el-button @click="
            dialogFormVisible = false;
            changestate = '';
          ">取 消</el-button>
        <el-button type="primary" @click="ModifyState">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改医生" :visible.sync="editdoc">
      <!-- <p>请给{{KID}}修改指定医生</p> -->
      <p>患者 {{row.Cust_ID}} 的 {{row.Dis_Name}} 问诊单 {{row.KID}}</p>
      请搜索医生：
      <el-input placeholder="请输入精确的姓名或电话号" clearable prefix-icon="el-icon-search" v-model="search" style="width: 30%;margin-left: 20px;">
      </el-input>
      <el-button type="primary" style="margin-left:20px" @click="query1">查询</el-button>
      <p style="color: #909399;font-size: 14px;">请先审核诊单，在下发给医生。</p>
      <el-table v-if="docmsg != ''" style="margin-top:40px;width: 100%" :data="docmsg" border>
        <el-table-column width="80">
          <template slot-scope="scope">
            <el-checkbox v-model="checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="hospital" label="所在医院"></el-table-column>
        <el-table-column prop="goodat" label="擅长治疗"></el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editdoc = false">取 消</el-button>
        <el-button type="primary" @click="AssignDoctor">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import preview from "../components/preview.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      searchcustid: "",
      searchfiel: "",
      list: [],
      EditZD: false,
      EditMsg: {
        kid: "",
        custid: "",
        noedit: "",
      },
      dialogFormVisible: false,
      changestate: "",
      options: [
        { id: 0, value: "未填写" },
        { id: 1, value: "提交" },
        { id: 2, value: "审核" },
        { id: 3, value: "打回" },
        { id: 6, value: "已下发" },
        { id: 9, value: "删除" },
      ],
      editdoc: false,
      search: "",
      checked: "",
      docmsg: "",
      uid: sessionStorage.getItem("id"),
      servicerid: sessionStorage.getItem("servicer_id"),
      KID: "",
      row: "",
      shenhe: false,
      pdfloading: false,
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      searchstate: 1,
      showpage: true,
    };
  },
  components: {
    preview,
  },
  mounted() {
    this.getall(1, "1");
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      if (this.searchfiel == "" && this.searchcustid == "") {
        this.getall(currentPage, this.searchstate);
      }
    },
    getall(page, state) {
      this.axios
        .get(
          "/field/get_z_field_dismains?state=" +
            state +
            "&page=" +
            page +
            "&size=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    transformstate(val) {
      var str = "";
      var dic = {
        0: "未填写",
        1: "提交",
        2: "审核",
        3: "打回",
        6: "已下发",
        9: "删除",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    query() {
      this.showpage = false;
      this.searchstate = "";
      this.list = [];
      if (this.searchfiel == "" && this.searchcustid == "") {
        this.$message({
          message: "请输入搜索条件",
          type: "error",
        });
        return;
      }
      if (
        (this.searchfiel !== "" ||
          this.searchfiel !== null ||
          this.searchfiel !== undefined) &&
        this.searchcustid == ""
      ) {
        this.axios
          .get("/gu/get_z_field_dismain_by_kid?kid=" + this.searchfiel)
          .then((res) => {
            if (res.data.code == 0) {
              this.list.push(res.data.result);
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (
        (this.searchcustid !== "" ||
          this.searchcustid !== null ||
          this.searchcustid !== undefined) &&
        this.searchfiel == ""
      ) {
        this.axios
          .get("/field/get_z_field_dismains?cust_id=" + this.searchcustid)
          .then((res) => {
            if (res.data.code == 0) {
              this.list = res.data.result;
              this.totalCount = res.data.count;
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    lookup(row) {
      //   sessionStorage.setItem("Cust_ID", row.Cust_ID);
      //   // sessionStorage.setItem('MedicalType', row.State);
      //   this.$router.push({ path: "/medicalOrderDetail", query: { id: row.KID } });
      this.EditZD = true;
      this.EditMsg.kid = row.KID;
      this.EditMsg.custid = row.Cust_ID;
      this.EditMsg.noedit = false;
    },
    fpys(row) {
      this.editdoc = true;
      this.row = row;
      this.KID = row.KID;
      this.search = "";
      this.docmsg = "";
    },
    query1() {
      this.axios
        .post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keyword: this.search, is_flag: "all" })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.docmsg = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: rs.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AssignDoctor() {
      if (this.checked) {
        // this.axios.post("/gu/update_gu_cust_info_docid", this.qs.stringify({ x_cust_id: this.Cust_ID, doc_id: this.docmsg[0].id, uid: this.uid }))
        this.axios
          .post(
            "/gu/update_z_field_dismain_docid",
            this.qs.stringify({
              x_cust_id: this.Cust_ID,
              doc_id: this.docmsg[0].id,
              kid: this.KID,
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.editdoc = false;
              this.docmsg = "";
              this.search = "";
              this.query();
              //   this.reload();
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message({
          message: "请选择医生",
          type: "info",
        });
      }
    },
    ModifyState() {
      if (this.changestate == 3) {
        this.changestate = 0;
      }
      if (
        this.changestate === "" ||
        this.changestate == null ||
        this.changestate == undefined
      ) {
        this.$message({
          message: "请选择状态",
          type: "error",
        });
        return;
      }
      if (
        this.row.KID == "" ||
        this.row.KID == null ||
        this.row.KID == undefined
      ) {
        this.$message({
          message: "没有KID",
          type: "error",
        });
        return;
      }
      if (
        this.servicerid == "" ||
        this.servicerid == null ||
        this.servicerid == undefined
      ) {
        this.$message({
          message: "没有服务ID",
          type: "error",
        });
        return;
      }
      if (this.changestate == 2) {
        this.EditZD = true;
        this.EditMsg.kid = this.row.KID;
        this.EditMsg.custid = this.row.Cust_ID;
        this.EditMsg.noedit = false;
        this.shenhe = true;
        // this.subTJshenhe(
        //   "确认审核用户:" + this.row.Cust_ID + "的" + this.row.Dis_Name + ":" + this.row.KID + "诊单吗?",
        //   this.row.KID,
        //   this.row.Cust_ID,
        //   this.row.Doc_ID
        // );
      } else {
        this.axios
          .post(
            "/gu/update_z_field_dismain_state",
            this.qs.stringify({
              state: this.changestate,
              kid: this.row.KID,
              uid: this.servicerid,
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              // if (this.changestate == 2) {
              //   if (
              //     this.row.Cust_ID == "" ||
              //     this.row.Cust_ID == null ||
              //     this.row.Cust_ID == undefined
              //   ) {
              //     return;
              //   }
              //   if (
              //     this.row.Doc_ID == "" ||
              //     this.row.Doc_ID == null ||
              //     this.row.Doc_ID == undefined
              //   ) {
              //     return;
              //   }
              //   if (this.row.KID == "" || this.row.KID == null || this.row.KID == undefined) {
              //     return;
              //   }
              //   this.axios
              //     .post(
              //       "/gu/insert_t_hytl_ta",
              //       this.qs.stringify({
              //         x_cust_id: this.row.Cust_ID,
              //         doc_id: this.row.Doc_ID,
              //         kid: this.row.KID,
              //       })
              //     )
              //     .then((res) => {
              //       if (res.data.code == 0) {
              //         this.$message({
              //           message: "修改成功",
              //           type: "success",
              //         });
              //       } else {
              //         this.$message({
              //           message: res.data.msg,
              //           type: "error",
              //         });
              //       }
              //     });
              // }

              this.dialogFormVisible = false;

              // this.$set(this.list,'State',this.changestate)
              // this.State = this.changestate;
              // sessionStorage.setItem("MedicalType", this.changestate);
              // this.reload();
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (this.searchfiel != "" && this.searchcustid != "") {
        this.list = [];
        this.query();
      }
    },
    subTJshenhe(str, zdID, custid, docid) {
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (zdID == "" || zdID == null || zdID == undefined) {
            return;
          }
          if (
            this.servicerid == "" ||
            this.servicerid == null ||
            this.servicerid == undefined
          ) {
            return;
          }
          this.axios
            .post(
              "/gu/update_z_field_dismain_state",
              this.qs.stringify({ state: "2", kid: zdID, uid: this.servicerid })
            )
            .then((res) => {
              // console.log(res);
              if (res.data.code == 0) {
                if (custid == null || custid == "" || custid == undefined) {
                  return;
                }
                if (docid == null || docid == "" || docid == undefined) {
                  return;
                }
                if (zdID == null || zdID == "" || zdID == undefined) {
                  return;
                }
                this.axios
                  .post(
                    "/gu/insert_t_hytl_ta",
                    this.qs.stringify({
                      x_cust_id: custid,
                      doc_id: docid,
                      kid: zdID,
                    })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.$message({
                        message: "审核成功",
                        type: "success",
                      });
                      this.$refs.uppdf.saveImage("html2canvas", zdID, "");
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                      });
                    }
                  });
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    shengchengpdf(row) {
      this.pdfloading = true;
      this.axios.get("/pdf/create_pdf_chufang?kid=" + row.KID).then((res) => {
        if (res.data.code == 0) {
          this.pdfloading = false;
          var url = "http://192.168.11.253:8080/public/" + res.data.file;
          window.open(url, "_blank");
        } else {
          this.pdfloading = false;
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
.opt p{
  margin: 0 0 8px 0;
}
</style>
